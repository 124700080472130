export const initFacebookPixel = () => {
  if (typeof window !== 'undefined') {
    window.fbq =
      window.fbq ||
      function () {
        (window.fbq.q = window.fbq.q || []).push(arguments);
      };
    window.fbq.l = new Date();

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://connect.facebook.net/en_US/fbevents.js';
    document.head.appendChild(script);

    window.fbq('init', '1788853582063135');
    window.fbq('track', 'PageView');
  }
};
